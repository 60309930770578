import { querySelectorAll, querySelectorRequired } from "jslib/utils";
import { NonNegativeInteger } from "./types";
import * as utils from "./utils";
import { notificationStore } from "./homepage/stores";
import Navbar from "./homepage/Navbar.svelte";
import NotificationPage from "./homepage/NotificationPage.svelte";

let startTimestamp = 0;
/**
 * "activates" a filmstrip for an element that contains the necessary "data-" attributes.
 * @param targetElement HTML Element reference to bind to
 */
export function activateFilmstrip(targetElement: HTMLElement) {
    if (targetElement.getElementsByClassName("fs_sprite").length === 0) { // construct dom tree if not present
        targetElement.innerHTML =
            "<div class=\"fs_position\"></div>"
            + "<div class=\"selectable fs_description\"></div>"
            + "<div class=\"fs_thickness\"></div>"
            + "<div class=\"fs_sprite\"></div><br />"
            + "<i class=\"fa fs_left fa-chevron-left\"></i>"
            + "<input class=\"fs_control\" type=\"range\" />"
            + "<i class=\"fa fs_right fa-chevron-right\"></i>"
            + targetElement.innerHTML;
    }

    const data = {
        description: targetElement.getAttribute("data-description"),
        instances: targetElement.getAttribute("data-instances"),
        instancesInt: 0,
        src: targetElement.getAttribute("data-src"),
        thickness: targetElement.getAttribute("data-thickness"),
    };

    if (data.instances) {
        data.instancesInt = parseInt(data.instances, 10);
    }

    const sprite = targetElement.getElementsByClassName("fs_sprite")[0] as HTMLElement;
    const control = targetElement.getElementsByClassName("fs_control")[0] as HTMLInputElement;
    const position = targetElement.getElementsByClassName("fs_position")[0] as HTMLElement;
    const thickness = targetElement.getElementsByClassName("fs_thickness")[0];
    const description = targetElement.getElementsByClassName("fs_description")[0];

    if (data.thickness) {
        thickness.innerHTML = data.thickness + "mm";
    }

    if (data.description) {
        description.innerHTML = data.description;
    }

    if (!data.instances) { // TODO: Verify
        position.style.visibility = "hidden";
    }

    control.setAttribute("min", "0");
    control.setAttribute("max", "39");
    control.value = "20";

    function onControlChange() {
        const frame = parseInt(control.value, 10);
        const offset = -frame * 256;

        // use same slice determination algorithm from dicomimport.py
        if (data.instances) {
            let slice = Math.round(frame * (data.instancesInt / 39)) + 1;
            slice = Math.min(data.instancesInt, slice);
            position.innerHTML = `${slice} / ${data.instances}`;
        }
        sprite.style.backgroundPosition = `0px ${offset}px`;
        sprite.style.backgroundPositionX = `${offset}px`;
    }
    control.onchange = onControlChange;
    control.onmousemove = onControlChange;
    onControlChange();

    function onWheel(event: WheelEvent) {
        event = event || (window.event as WheelEvent);
        const delta = (event as any).wheelDelta || event.detail;
        // no concat. pls // TODO: Why was this Number(x) with parseInt(x)? difference?
        let frame = (delta > 0) ? (Number(control.value) + 1) : (parseInt(control.value, 10) - 1);

        frame = Math.max(frame, 0);
        frame = Math.min(frame, 39);

        control.value = "" + frame;
        onControlChange();

        if (event.preventDefault) {
            event.preventDefault();
        }

        // cancel event bubbling, no page scroll
        return false;
    }

    if (typeof (targetElement.addEventListener) !== "undefined") {
        targetElement.addEventListener("DOMMouseScroll", onWheel, { passive: false });
        targetElement.addEventListener("mousewheel", onWheel, { passive: false });
    } else {
        (targetElement as any).onmousewheel = onWheel;
    }

    const left = (targetElement.getElementsByClassName("fs_left")[0] as HTMLInputElement);
    left.addEventListener("click", _event => {
        const val = parseInt(control.value, 10);
        if (val > 0) {
            control.value = "" + (val - 1);
        }
        onControlChange();
    });

    const right = (targetElement.getElementsByClassName("fs_right")[0] as HTMLInputElement);
    right.addEventListener("click", _event => {
        const val = parseInt(control.value, 10);
        if (val < 39) {
            control.value = "" + (val + 1);
        }
        onControlChange();
    });

    // polls image until ready, then displays it
    function loadImage() {
        const xhr = new XMLHttpRequest();
        if (data.src) {
            xhr.open("HEAD", data.src);
        } else {
            throw new Error("No image data found");
        }
        xhr.onreadystatechange = function() {
            if (this.readyState === this.DONE) {
                // consider this an explicit "pending" -- anything else could be an
                // error, which may be shown as an image to the client
                if (this.status === 202) {
                    setTimeout(loadImage, 800);
                } else {
                    sprite.style.backgroundImage = `url(${data.src})`;
                }
            }
        };
        xhr.send();
    }

    loadImage();
}


/**
 * Handler to be called on entering the "scan selection" page.
 * @param chosenSeriesID SeriesID of the "selected" scan; may be empty.
 */
export function onStudyPage() {
    /* Activate filmstrips */
    for (const filmstripElement of querySelectorAll(".filmstrip") as [HTMLElement]) {
        activateFilmstrip(filmstripElement);
    }
}

function wireUpPatientTable() {
    for (const studyElement of querySelectorAll(".study-row") as [HTMLElement]) {
        studyElement.onclick = function() {
            const href = (this as HTMLElement).getAttribute("data-href");
            if (!href) {
                throw new Error("data-href must be provided for each study listing");
            }
            document.location.href = href;
        };
    }
}


export function onWelcomePage() {
    // focus input element
    const input: HTMLInputElement = querySelectorRequired("#query_name");
    input.focus();
    wireUpPatientTable();
}


/**
 * Handler to be called when entering the "study selection" page.
 * @param orderBy ordering string (may be empty)
 */
export function onStudySelectionPage(orderBy: string) {

    wireUpPatientTable();

    const query = querySelectorRequired("#query_name", HTMLInputElement);
    if (query.value) {
        query.select();
    } else {
        query.focus();
    }

    const headers = (document.querySelectorAll(".patients th") as NodeListOf<HTMLElement>);
    for (let i = 0; i < headers.length; i++) {
        const header = headers[i];
        const orderByCurrent = header.getAttribute("data-order-by");

        if (!orderByCurrent) {
            continue;
        }

        const isAscending = orderBy.substr(0, 1) !== "-";
        const ordering = orderBy.substr(isAscending ? 0 : 1);
        // default sorting
        let clickOrderBy = `-${orderByCurrent}`;

        // icon
        if (orderByCurrent === ordering) {
            /* add class to listings */
            const listings = querySelectorAll(".patients tbody tr.patient-row") as [HTMLElement];
            for (const listing of listings) {
                const cells = listing.querySelectorAll("td");
                if (cells.length < i) {
                    continue;
                }
                cells[i].className = cells[i].className + (isAscending ? " sortasc" : " sortdsc");
            }
            header.className = header.className + (isAscending ? " sortasc" : " sortdsc");
            clickOrderBy = isAscending ? clickOrderBy : orderByCurrent;
        }
        // closure to retain var in loop
        (clickOrderByLocal => {
            header.onclick = () => {
                querySelectorRequired(".search-submit input[name=\"order_by\"]", HTMLInputElement).value = clickOrderByLocal;
                querySelectorRequired(".search", HTMLFormElement).submit();
            };
        })(clickOrderBy);
    }
}

/**
 * Updates a progress indicator with the current study/scan upload progress.
 */
async function updateBarLoop(event: ProgressEvent) {
    const progressBarElement = querySelectorRequired("#progress .bar", HTMLDivElement);
    const progressContainerElement = querySelectorRequired("#progress", HTMLDivElement);
    const progressMessageElement = querySelectorRequired("#progress .msg", HTMLDivElement);
    const progressETAElement = querySelectorRequired("#progress .eta", HTMLDivElement);

    function showProcessingModal() {
        const modalElement = querySelectorRequired("#modal", HTMLDivElement);
        modalElement.style.display = "block";
        progressBarElement.style.width = "100%";
        progressContainerElement.style.opacity = "0";
    }

    if (!event.lengthComputable) {  // will not happen in our supported browsers
        console.warn("!event.lengthComputable: upload progress display unavailable");
        showProcessingModal();
    }

    const progressSoFar = (100 * event.loaded) / event.total;
    const progressHuman = Math.floor(progressSoFar);
    const timeNowMS = new Date().getTime();
    const bps = event.loaded * 1000 / (timeNowMS - startTimestamp);
    const eta = (event.total - event.loaded) / bps;

    if (progressSoFar >= 100) {
        showProcessingModal();
        //  uncomment this to stop polling at 100%.
        //  Polling is kept running to keep the VPN alive so the main
        //  upload request comes back when you're on a connection with
        //  low keepalive.
        // clearTimeout(timeout)
    }
    progressBarElement.style.width = `${progressHuman}%`;
    progressMessageElement.textContent = `Uploading. ${progressHuman}% complete`;
    progressETAElement.innerHTML = utils.humanEta(eta);
}

/**
 * Handler to be called when entering the "upload ZIP" page.
 */
export function onUploadPage() {
    const uploadFormElement = querySelectorRequired("#upload", HTMLFormElement);

    function onSubmit(event?: Event) {

        // filter multiple calls
        if (startTimestamp > 0) {
            return false;
        }
        startTimestamp = new Date().getTime();
        const messageElement = querySelectorRequired("#error-message");
        messageElement.classList.add("hidden");

        const preUploadElements = (querySelectorAll(".instructions, .guide, .heading, label.button") as [HTMLElement]);
        const uploadElements = (querySelectorAll("#modal, #progress") as [HTMLElement]);

        // hide pre-upload UI, show upload UI
        for (const element of preUploadElements) {
            element.classList.add("hidden");
        }
        for (const element of uploadElements) {
            element.classList.remove("hidden");
        }

        const progressElement = querySelectorRequired("#progress", HTMLDivElement);
        progressElement.style.position = "relative";
        progressElement.style.top = "40px";
        querySelectorRequired("#progress .msg").textContent = "Starting upload...";
        querySelectorRequired("body, html", HTMLElement).style.cursor = "wait";

        const xhr = new XMLHttpRequest();
        xhr.open("post", window.location.href);
        const csrfToken = utils.getCookie("csrftoken");
        if (csrfToken) {
            xhr.setRequestHeader("X-CSRFToken", csrfToken);
        }
        xhr.timeout = 0;  // explicit: no timeout for this XHR
        const formData = new FormData(uploadFormElement);
        xhr.upload.onprogress = updateBarLoop;
        xhr.onload = function(e) {
            if (!e.isTrusted) {
                return;
            }
            startTimestamp = 0; // reset call so it can be used again
            const response = JSON.parse(xhr.responseText);
            if (response.error) {
                // show error
                querySelectorRequired("#error-message").classList.remove("hidden");
                querySelectorRequired("#error-message .message").textContent = response.message;
                querySelectorRequired("#error-message .sub").textContent = response.messageSub;
                if ("patient_id" in response) {
                    const element = querySelectorRequired("#error-message .link") as HTMLAnchorElement;
                    element.textContent = "View Patient";
                    element.href = `/patient/${response.patient_id}`;
                    if ("study_id" in response) {
                        element.textContent = "View CT Study";
                        element.href = `/patient/${response.patient_id}#study/${response.study_id}`;
                    }
                }

                // hide upload UI, show pre-upload UI
                for (const element of uploadElements) {
                    element.classList.add("hidden");
                }
                for (const element of preUploadElements) {
                    element.classList.remove("hidden");
                }
                // this is handled separately due to how the modals work
                const modalElement = querySelectorRequired("#modal", HTMLDivElement);
                modalElement.style.display = "none";
                // reset cursor
                querySelectorRequired("body, html", HTMLElement).style.cursor = "default";
            } else {
                window.location.href = response.redirect;
            }
        };
        xhr.send(formData);

        utils.keepAlive(10 * 60);  // ensure session does not timeout during upload, refresh every 10 minutes

        // show progress bar
        const progressBarElement = querySelectorRequired("#progress", HTMLElement);
        utils.removeClass(progressBarElement, "hidden");

        if (event) {
            event.preventDefault();
        }
        return true;
    }

    const uploadFileElement = querySelectorRequired("#upload input[type=file]", HTMLInputElement);
    uploadFileElement.addEventListener("change", onSubmit);
}


/**
 * "Activates" lightbox; to be called when on a page containing Lightbox DOM.
 */
export function activateLightbox() {
    // NOTE: Appends an element using the existing DOM instead of appending to
    // innerHTML which would re-write the DOM and leave potential stale
    // references with other code.
    const $lightbox = document.createElement("div");
    $lightbox.id = "lightbox";
    $lightbox.innerHTML = `
        <div id="lightbox-loading">Loading...</div>
        <div id="lightbox-position"></div>
        <i id="lightbox-left" class="fa fa-caret-left"></i>
        <i id="lightbox-close" class="fa fa-times"></i>
        <i id="lightbox-right" class="fa fa-caret-right"></i>
        <div id="lightbox-subject"></div>
        <div id="lightbox-caption"></div>
        <div id="lightbox-fullscreen">
            <i class="fa fa-arrows-alt"></i> Fullscreen
        </div>
        <div id="lightbox-download">
            <i class="fa fa-download"></i> Download full-size
        </div>
    `;
    document.body.appendChild($lightbox);

    const lightboxElement = querySelectorRequired("#lightbox", HTMLElement);
    const lightboxLeftElement = querySelectorRequired("#lightbox-left", HTMLElement);
    const lightboxRightElement = querySelectorRequired("#lightbox-right", HTMLElement);
    const lightboxDownloadElement = querySelectorRequired("#lightbox-download", HTMLElement);
    const lightboxSubjectElement = querySelectorRequired("#lightbox-subject", HTMLElement);
    const lightboxCaptionElement = querySelectorRequired("#lightbox-caption", HTMLElement);
    const lightboxPositionElement = querySelectorRequired("#lightbox-position", HTMLElement);
    const lightboxFSElement = querySelectorRequired("#lightbox-fullscreen", HTMLElement);

    // preloading
    const nextimg = new Image();
    const hoverimg = new Image();
    const urls: string[] = [];
    const captions: string[] = [];
    const downloadables: boolean[] = [];

    (querySelectorAll("#lightbox-close, #lightbox-subject") as [HTMLElement]).forEach(e => {
        e.addEventListener("click", () => {
            lightboxElement.style.display = "none";
            lightboxFSElement.style.display = "block";
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if ((document as any).msExitFullscreen) {
                (document as any).msExitFullscreen();
            } else if ((document as any).mozCancelFullScreen) {
                (document as any).mozCancelFullScreen();
            } else if ((document as any).webkitExitFullscreen) {
                (document as any).webkitExitFullscreen();
            }
            window.location.hash = "";
        });
    });

    (querySelectorAll("img[data-original") as [HTMLElement]).forEach(e => {
        const url = e.getAttribute("data-original");
        if (!url) {
            throw new Error("missing data-original attribute");
        }
        urls.push(url);
        const cursor = urls.length - 1;
        const caption = e.getAttribute("data-caption");
        const downloadable = e.getAttribute("data-downloadable") === "true";
        if (!caption) {
            throw new Error("missing data-caption attribute");
        }
        captions.push(caption);
        downloadables.push(downloadable);
        e.addEventListener("click", () => {
            window.location.hash = "lightbox" + urls[cursor];
        });
        (function(element) {
            element.addEventListener("mouseover", () => {
                const orig = element.getAttribute("data-original");
                if (!orig) {
                    throw new Error("missing data-original attribute");
                }
                hoverimg.src = orig;
            });
        }(e));
    });

    lightboxRightElement.addEventListener("click", () => {
        const url = document.location.hash.slice(9);
        const cursor = urls.indexOf(url);
        if (cursor < (urls.length-1)) {
            window.location.hash = "lightbox" + urls[cursor + 1];
        }
    });

    lightboxLeftElement.addEventListener("click", () => {
        const url = document.location.hash.slice(9);
        const cursor = urls.indexOf(url);
        if (cursor > 0) {
            window.location.hash = "lightbox" + urls[cursor - 1];
        }
    });

    lightboxDownloadElement.addEventListener("click", () => {
        const url = document.location.hash.slice(9);
        window.open(url);
    });

    function loadImageFromHash() {

        if (window.location.hash.substr(0, 9) !== "#lightbox") {
            return;
        }

        const url = document.location.hash.slice(9);
        const cursor = urls.indexOf(url);

        lightboxElement.style.display = "block";
        lightboxSubjectElement.style.backgroundImage = `url("${url}")`;
        lightboxCaptionElement.textContent = captions[cursor];

        if (downloadables[cursor]) {
            lightboxDownloadElement.style.display = "block";
        } else {
            lightboxDownloadElement.style.display = "none";
        }

        if (cursor >= urls.length - 1) {
            lightboxRightElement.style.display = "none";
        } else {
            lightboxRightElement.style.display = "block";
            // preload
            nextimg.src = urls[cursor + 1];
        }

        if (cursor === 0) {
            lightboxLeftElement.style.display = "none";
        } else {
            lightboxLeftElement.style.display = "block";
            // preload;
            nextimg.src = urls[cursor - 1];
        }

        lightboxPositionElement.textContent = `${cursor + 1}/${urls.length}`;
    }

    loadImageFromHash();
    window.addEventListener("hashchange", loadImageFromHash);

    window.addEventListener("keydown", event => {
        if (event.keyCode === 37) {
            lightboxLeftElement.click();
        } else if (event.keyCode === 39) {
            lightboxRightElement.click();
        }
    });

    lightboxFSElement.addEventListener("click", () => {
        if (lightboxElement.requestFullscreen) {
            lightboxElement.requestFullscreen();
        } else if ((lightboxElement as any).msRequestFullscreen) {
            (lightboxElement as any).msRequestFullscreen();
        } else if ((lightboxElement as any).mozRequestFullScreen) {
            (lightboxElement as any).mozRequestFullScreen();
        } else if ((lightboxElement as any).webkitRequestFullscreen) {
            (lightboxElement as any).webkitRequestFullscreen();
        }
        lightboxFSElement.style.display = "none";
    });
}


/**
 * Handler to be called when entering the "upload ZIP" page.
 */
export function onFileUploadPage() {
    const uploadFormElement = querySelectorRequired("#uploadfile", HTMLFormElement);

    function onSubmit(event?: Event) {

        // filter multiple calls
        if (startTimestamp > 0) {
            return false;
        }
        startTimestamp = new Date().getTime();
        const messageElement = querySelectorRequired("#error-message");
        messageElement.classList.add("hidden");

        const uploadElements = (querySelectorAll("#file_progress") as [HTMLElement]);

        // hide pre-upload UI, show upload UI
        for (const element of uploadElements) {
            element.classList.remove("hidden");
        }
        const progressElement = querySelectorRequired("#file_progress", HTMLDivElement);
        progressElement.style.position = "relative";
        progressElement.style.top = "40px";
        querySelectorRequired("#file_progress .msg").textContent = "Starting upload...";
        querySelectorRequired("body, html", HTMLElement).style.cursor = "wait";

        const xhr = new XMLHttpRequest();
        xhr.open("post", window.location.href);
        const csrfToken = utils.getCookie("csrftoken");
        if (csrfToken) {
            xhr.setRequestHeader("X-CSRFToken", csrfToken);
        }
        xhr.timeout = 0;  // explicit: no timeout for this XHR
        const formData = new FormData(uploadFormElement);
        xhr.upload.onprogress = updateFileUploadBarLoop;
        xhr.onload = function(e) {
            if (!e.isTrusted) {
                return;
            }
            startTimestamp = 0; // reset call so it can be used again
            const response = JSON.parse(xhr.responseText);
            if (response.error) {
                // show error
                querySelectorRequired("#error-message").classList.remove("hidden");
                querySelectorRequired("#error-message .message").textContent = response.message;
                querySelectorRequired("#error-message .sub").textContent = response.messageSub;

                // hide upload UI, show pre-upload UI
                for (const element of uploadElements) {
                    element.classList.add("hidden");
                }

                // reset cursor
                querySelectorRequired("body, html", HTMLElement).style.cursor = "default";
            }
            else {
                querySelectorRequired("#error-message").classList.add("hidden");
                querySelectorRequired("body, html", HTMLElement).style.cursor = "default";
                progressElement.classList.add("hidden");

                const fileList = querySelectorRequired("#file-container");
                const newElement = document.createElement("div");

                newElement.innerHTML=`<div class="file-item">
                <span class="file-widget-icon">
                    <a href="${response.upload_url}">
                    <i class="fa ${response.upload_icon}"></i>
                    </a>
                </span>
                <span class="file-widget-info">
                    <span class="file-name" >
                    <a href="${response.upload_url}"> ${response.upload_name}</a>
                    </span>
                    <span class="file-date"> ${response.date}</span>
                    <span class="file-type-size"> ${response.file_type} - ${response.file_size}</span>
                </span>
                <span class="file-widget-delete" onclick="event.stopPropagation(); vault.scanmanager.removeFile('${response.delete_url}', '')">
                    <i class="fa fa-times"></i>
                </span>
                </div>`;
                fileList.insertBefore(newElement, fileList.firstChild);

            }
        };
        xhr.send(formData);

        utils.keepAlive(10 * 60);  // ensure session does not timeout during upload, refresh every 10 minutes

        // show progress bar
        const progressBarElement = querySelectorRequired("#file_progress", HTMLElement);
        utils.removeClass(progressBarElement, "hidden");

        const uploadFileInput = querySelectorRequired("#uploadfile input[type=file]", HTMLInputElement);
        uploadFileInput.value = "";

        if (event) {
            event.preventDefault();
        }
        return true;

    }

    const uploadFileElement = querySelectorRequired("#uploadfile input[type=file]", HTMLInputElement);
    uploadFileElement.addEventListener("change", onSubmit);
}

async function updateFileUploadBarLoop(event: ProgressEvent) {
    const progressBarElement = querySelectorRequired("#file_progress .bar", HTMLDivElement);
    const progressMessageElement = querySelectorRequired("#file_progress .msg", HTMLDivElement);
    const progressETAElement = querySelectorRequired("#file_progress .file_eta", HTMLDivElement);

    if (!event.lengthComputable) {  // will not happen in our supported browsers
        console.warn("!event.lengthComputable: upload progress display unavailable");
    }

    const progressSoFar = (100 * event.loaded) / event.total;
    const progressHuman = Math.floor(progressSoFar);
    const timeNowMS = new Date().getTime();
    const bps = event.loaded * 1000 / (timeNowMS - startTimestamp);
    const eta = (event.total - event.loaded) / bps;
    progressBarElement.style.width = `${progressHuman}%`;
    progressMessageElement.textContent = `Uploading. ${progressHuman}% complete`;
    progressETAElement.innerHTML = utils.humanEta(eta);
}


export function removeFile(removeFileURL: any, redirectURL: any) {
    const tintElement = querySelectorRequired(".tint", HTMLElement);
    const deleteModal = querySelectorRequired("#delete-modal", HTMLElement);
    const deleteCancelButton = querySelectorRequired("#delete_cancel_button", HTMLElement);
    const deleteForm = querySelectorRequired("#delete_confirm_button", HTMLElement);
    deleteModal.style.display = "block";
    tintElement.style.display = "block";

    deleteCancelButton.onclick = () => {
        deleteModal.style.display = "none";
        tintElement.style.display = "none";
    };

    deleteForm.onclick = () => {

        const csrftoken = utils.getCookie("csrftoken");
        const xmlhttp = new XMLHttpRequest();

        xmlhttp.onreadystatechange = function () {
            if (xmlhttp.readyState === XMLHttpRequest.DONE) {
                if (xmlhttp.status === 200) {
                    window.location.replace(redirectURL);
                } else {
                    // eslint-disable-next-line no-alert
                    alert("There was an error");
                }
            }
        };

        xmlhttp.open("POST", removeFileURL, true);
        if (csrftoken) {
            xmlhttp.setRequestHeader("X-CSRFToken", csrftoken);
        } else {
            throw new Error("X-CSRFToken is null");
        }
        xmlhttp.send();

        const fa = querySelectorRequired("#delete-modal .fa", HTMLElement);
        fa.className = `${fa.className} fa-circle-o-notch fa-spin`;
        const headings = document.querySelectorAll("#delete-modal h1");
        if (headings.length > 0) {
            headings[0].innerHTML = "Deleting file...";
        }

        for (const deleteModalP of querySelectorAll("#delete-modal p") as [HTMLElement]) {
            deleteModalP.style.visibility = "hidden";
        }

        for (const buttonElement of querySelectorAll("#delete-modal .button") as [HTMLElement]) {
            buttonElement.style.visibility = "hidden";
        }
    };
}


/**
 * Timer class that can be configured with an initial offset
 */
export class Timer {
    constructor(
        private readonly interval: NonNegativeInteger,
        private readonly offset = NonNegativeInteger.create(0),
        private offsetApplied = false,
    ) {}

    public async sleep() {
        if (!this.offsetApplied) {
            await utils.sleep(this.offset.valueOf());
            this.offsetApplied = true;
        }
        await utils.sleep(this.interval.valueOf());
    }
}


export function navbarMenus() {
    const target = document.getElementById("header-top");
    const script = document.getElementById("navbar-data");

    if (!target || !script) {
        return;
    }

    const endpoint = Boolean(script.dataset.endpoint);
    const showNotifications = Boolean(script.dataset.shownotifications);
    const userAuthenticated = Boolean(script.dataset.userauthenticated);
    const userDisplayName = script.dataset.userdisplayname as string;
    const showCompanionAppSignUp = Boolean(script.dataset.showcompanionappsignup);
    const demoMode = Boolean(script.dataset.demomode);

    if (userAuthenticated) {
        notificationStore.pollNotifications();
    }

    new Navbar({
        target,
        props: {
            endpoint,
            userAuthenticated,
            showNotifications,
            userDisplayName,
            showCompanionAppSignUp,
            demoMode
        }
    });
}

export function onNotificationPage() {
    const target = document.getElementById("notifications-container");

    if (!target) {
        return;
    }

    new NotificationPage({
        target
    });
}

export function onPartnerDashboard() {
    // form submits
    const selects = document.querySelectorAll<HTMLSelectElement>("#dashboard-filters select");
    const form = document.querySelector<HTMLFormElement>("#dashboard-filters");
    const downloadInput = document.getElementById("download") as HTMLInputElement;
    if (!form) {
        throw new Error("Form requried");
    }

    for (const select of selects) {
        select.addEventListener("change", () => {
            downloadInput.value = "false";
            form?.submit();
        });
    }

    // reset
    const resetButton = document.getElementById("reset");
    resetButton?.addEventListener("click", () => {console.log("reset clicked"); window.location.replace(window.location.href);});

    // download
    const downloadButton = document.getElementById("download-button");
    downloadButton?.addEventListener("click", (event) => {
        event.preventDefault();
        downloadInput.value = "true";
        form.submit();
    });
}


// ewww... moved over from old notifications.ts script
export function removeElement(el: any) {
    el.parentNode.parentNode.style.display="none";
}

/*
 * A module for basic utility functions that don't fit in elsewhere
 */

(function (factory) {
    // Variant of this UMD module pattern:
    // https://github.com/umdjs/umd/blob/master/templates/returnExports.js
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define("jslib/utils", [], factory);
    } else if (typeof module === 'object' && module.exports) {
        // Node. Does not work with strict CommonJS, but
        // only CommonJS-like environments that support module.exports,
        // like Node.
        module.exports = factory();
    }
}(function () {

    "use strict";

    // Data
    var basepath = location.pathname.slice(0,-64);

    // Implementation

    function sendHttpRequest(request, successHandler, errorHandler) {

        // request defaults
        request.method = request.method || "GET"
        request.body = request.body || null;
        request.headers = request.headers || {}
        request.responseType = request.responseType || "text"

        function doRequest(requestSuccess, requestError) {
            var xhr = new XMLHttpRequest();
            xhr.open(request.method, request.url, true);
            xhr.responseType = request.responseType;

            Object.keys(request.headers).forEach(function(key) {
                xhr.setRequestHeader(key, request.headers[key])
            })

            xhr.onreadystatechange = function () {
                if (xhr.readyState !== 4) {
                    return;
                }

                if (xhr.status === 200) {
                    requestSuccess(xhr.response, xhr.statusText, xhr);
                }
                else {
                    requestError(xhr.statusText, xhr);
                }
            };

            xhr.send(request.method === "GET" ? null : request.body);
        }

        if (successHandler || errorHandler) {
            doRequest(successHandler, errorHandler);
        } else {
            return new Promise(function(resolve, reject) {
                doRequest(resolve, reject);
            });
        }

    }

    function doGet(url, successHandler, errorHandler, responseType) {
        return sendHttpRequest({url: url, responseType: responseType}, successHandler, errorHandler);
    }


    function querySelector(selector, elementtype) {
        var e = document.querySelector(selector);
        return (!elementtype || e instanceof elementtype)? e : undefined;
    }

    /**
     * Compares two arrays for shallow equality, using a supplied comparator
     * function to compare its elements
     * @param  comparator  - function used to compare the array elements
     * @param  a           - The first array
     * @param  b           - the second array
     * @return {boolean}
     */
    function arrayShallowEquals(comparator, a, b) {
        if (a === b) {
            return true;
        }
        if (a.length !== b.length) {
            return false;
        }
        for (var i = 0; i < a.length; i++) {
            if (!comparator(a[i], b[i])) {
                return false;
            }
        }
        return true;
    }

    function top_path(rest) {
        // Return a URL relative to the ITG server root
        return basepath + rest;
    }

    // Exported functions

    return {
        randchoice: function(seq) {
            // Return a random element from the sequence, akin to
            // Pythons random.choice. seq must support indexing
            // and length, like an array
            return seq[Math.floor(Math.random() * seq.length)];
        },

        now: function() {
            // Return the current time, in ms since Jan 1, 1970
            return (new Date()).getTime();
        },

        top_path: top_path,

        blob_path: function(hash, url_prefix) {
            return url_prefix ? url_prefix + "/blob/" + hash : top_path("blob/"+hash);
        },

        set_basepath: function(newvalue) { basepath = newvalue; },

        // Perform an asynchronous AJAX request
        get: doGet,

        // Convenience wrapper for getting JSON documents
        get_json: function(url, successHandler, errorHandler) {
            return doGet(url, successHandler, errorHandler, "json");
        },

        // More comprehensive http interface
        request: sendHttpRequest,

        // Load an image via an Image object, and proceed when the image is
        // available. If the optional second parameter is supplied, the src
        // of this object (assumed to be an <img> element) is also set once
        // the image is available.

        get_image: function(src, target) {
            var p = new Promise(function(resolve, reject) {
                var i = new Image();
                i.onload = function() { resolve(i); };
                i.onerror = reject;
                i.src = src;
            });

            if (target) {
                return p.then(function() {
                    target.src = src;
                });
            } else {
                return p;
            }
        },

        extend: function() {
            var name,
                target = arguments[0] || {},
                i = 1,
                item,
                length = arguments.length,
                copy;

            for ( ; i < length; i += 1) {
                item = arguments[i];
                if (item === null)  {
                    continue;
                }


                for (name in item) {
                    copy = item[name];
                    if (copy !== undefined) {
                        target[name] = copy;
                    }
                }
            }

            return target;
        },

        unique: function(a) {
            return a.sort().filter(function(value, index, array) {
                return (index === 0) || (value !== array[index-1]);
            });
        },

        forEachElementWithClassName: function(name, f) {
            Array.prototype.forEach.call(document.getElementsByClassName(name), f);
        },

        querySelector: querySelector,

        querySelectorAll: function(selector) {
            return Array.from(document.querySelectorAll(selector));
        },

        querySelectorRequired: function(selector, elementtype) {
            var e = querySelector(selector, elementtype);
            if (e) {
                return e;
            } else {
                throw new Error("No element matches selector: "+selector);
            }
        },

        /**
         * Test helper. Selects the element with the specified data-testid
         * attribute. This means we can avoid relying on CSS class and instance
         * IDs when selecting elements for testing.
         */
        getElementByTestId: function(testId, options) {
            var options = options || {};
            var parent = options.parent || document;
            return parent.querySelector(
                "[data-testid=\"" + testId + "\"]"
            );
        },

        // A wrapper to check for null values explicitly, mainly to facilitate
        // sensible type checking
        nonnull: function(x) {
            if (x === null || x === undefined) {
                throw new Error("Expected non-null value");
            } else {
                return x;
            }
        },

        /**
         * Runs the given function with the supplied object, then returns the object.
         */
        tap: function(fn) {
            return function(arg) {
                fn(arg);
                return arg;
            }
        },

        arrayShallowEquals: arrayShallowEquals,

        /**
         * Compare two objects for shallow equality.
         *
         * Two objects are shallow equal if:
         * - They are === equal, or;
         * - They have all the same own keys and the values of these keys are === equal
         * @param  a The first object
         * @param  b The second object
         * @return {boolean}
         */
        shallowEquals: function(a, b) {
            if (a === b) {
                return true;
            }
            var aKeys = Object.keys(a).sort();
            var bKeys = Object.keys(b).sort();
            var keysEqual = arrayShallowEquals(function(k1, k2) { return k1 === k2; }, aKeys, bKeys);
            if (!keysEqual) {
                return false;
            }
            for (var i in aKeys) {
                var key = aKeys[i];
                if (a[key] !== b[key]) {
                    return false;
                }
            }
            return true;
        }
    };

}));

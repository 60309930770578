<script lang="ts">
    import { onDestroy } from "svelte";
    import GenericDropdownMenu from "./GenericDropdownMenu.svelte";
    import type { INotification } from "./interfaces";
    import NavbarItem from "./NavbarItem.svelte";
    import { unreadNotificationStore, dropdownShowing } from "./stores";
    import { toggleDropdown, markAsRead } from "./utils";

    let preUpdate: INotification[] = [];
    let initialSubscribe = true;
    let ringthebell = false;

    function ringTheBell(value: boolean) {
        ringthebell = value;
    }

    $: lastFiveNotifications = $unreadNotificationStore.slice(0, 5);

    const thisDropdown = "notifications";

    const unsubscribe = unreadNotificationStore.subscribe(notifications => {
        // check whether any of the updates aren't in the preUpdate, ie they're new and play animation
        const updateIDs = notifications.map(notification => notification.id);
        const preUpdateIDs = preUpdate.map(notification => notification.id);

        for (const id of updateIDs) {
            if (!preUpdateIDs.includes(id)) {
                preUpdate = notifications;
                // don't run the first time round (subscribing) only on data being received.
                if (!initialSubscribe) {
                    ringTheBell(true);
                    setTimeout(() => { ringTheBell(false); }, 2000);
                    // only do it once even if there are multiple new notifcations
                    return;
                }
            }
            else {
                preUpdate = notifications;
            }
        }
        initialSubscribe = false;
    });

    onDestroy(unsubscribe);

</script>

<NavbarItem mobileHide={true}>
    <div class:ringthebell>
        <i class:ringthebell class="fa fa-bell-o notification-icon" id="notification-bell" on:click={() => toggleDropdown(thisDropdown)}></i>
        {#if $unreadNotificationStore.length !== 0}
        <div class="notification-count-circle" on:click={() => toggleDropdown(thisDropdown)}><span class="notification-count">{$unreadNotificationStore.length}</span></div>
        {/if}
    </div>
    {#if $dropdownShowing === thisDropdown}
    <GenericDropdownMenu title="Notifications" onClose={() => toggleDropdown()} wide={true}>
        <div class="dropdown-notifications">
            {#if $unreadNotificationStore.length === 0}
            <div class="no-notifications">
                    No new notifications!
            </div>
            {/if}
            {#each lastFiveNotifications as notification}
                <div class="dropdown-item-parent" on:click={() => { window.location.href = notification.url; }}>
                    <div class="top-row">
                        <div class="patient-name">{notification.patient}</div>
                        <span class="dropdown-time">{notification.created} ago</span>
                        <span class="dropdown-item-close" on:click|stopPropagation={() => markAsRead(notification.id)}>&times;</span>
                    </div>
                    <div>{notification.subject}</div>
                </div>
            {/each}
            <div class="view-all">
                <a href="/notifications">View all notifications</a>
            </div>
        </div>
    </GenericDropdownMenu>
    {/if}
</NavbarItem>

<style>
.patient-name{
    font-weight: bold;
    flex-grow: 1;
}

.notification-count-circle{
    position: absolute;
    top: 23px;
    right: 27px;
    padding: 6px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: rgb(225, 89, 10);
    color: white;
    cursor: pointer;
    transform: scale(1);
}

.notification-count{
    position: absolute;
    top: -1px;
    left: 5px;
    font-size: 1.4rem;
    font-weight: 900;
}

#notification-bell {
    position: absolute;
    top: 30px;
    right: 14px;
    font-size: 30px;
    cursor: pointer;
}

.ringthebell #notification-bell{
    animation-name: ring-the-bell;
    animation-duration: 0.8s;
    animation-iteration-count: 2;
    animation-timing-function: linear;
}

.ringthebell .notification-count-circle{
    transform: scale(0);
    animation-name: pop-into-view;
    animation-duration: 0.2s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes ring-the-bell{
    0% {transform: rotate(0deg)}
    25% {transform: rotate(15deg)}
    50% {transform: rotate(0deg)}
    75% {transform: rotate(-15deg)}
    100% {transform: rotate(0deg)}
}

@keyframes pop-into-view{
    0% {transform: scale(0)}
    80% {transform: scale(1.4)}
    100% {transform: scale(1)}
}

.top-row{
    display: flex;
    flex-direction: row;
}

  .dropdown-item-parent:hover {
    background-color: #dcdcdc;
    display: block;
    cursor: pointer;
  }

  .dropdown-item-parent {
    border-top: 0.5px solid #dcdcdc;
    padding: 8px;
  }

  .view-all{
    display: flex;
    align-items: center;
    flex-direction: column;
    border-top: 0.5px solid #dcdcdc;
    padding-top: 32px;
  }

  .dropdown-item-close {
    color: #666667;
    font-size: 2rem;
    margin-left: 16px;
  }

  .dropdown-item-close:hover {
    color: #ff1040;
    transition: 0.3s;
    cursor: pointer;
  }

  .dropdown-time{
    color: #3f228a;
    font-size: 1.3rem;
  }


  .no-notifications{
    padding-bottom: 32px;
    display: block;
    text-align: center;
  }
</style>
<script lang="ts">
    export let title: string;
    export let onClose: () => void;
    export let wide = false;
</script>

<div class="dropdown-wrapper" class:wide>
    <div class="dropdown-title">
        {title}
    </div>
    <div class="dropdown-body">
        <slot></slot>
        <hr>
        <div class="dropdown-close" on:click={onClose}><span class="close-cross">&times;</span>Close menu</div>
    </div>
</div>

<style>
    .dropdown-title{
        color: white;
        background-color: #3f228a;
        padding: 16px;
        text-align: center;
    }
    .dropdown-title:after {
        content: " ";
        position: absolute;
        right: 10px;
        top: -15px;
        border-top: none;
        border-right: 12px solid transparent;
        border-left: 12px solid transparent;
        border-bottom: 15px solid #3f228a;
    }
    .dropdown-wrapper{
        background-color:white;
        color: #3f228a;
        position: absolute;
        top: 80px;
        right: 7px;
        z-index: 6;
        width: 320px;
    }

    .wide{
        width: 480px;
    }

    .dropdown-body{
        padding: 32px;
    }

    .dropdown-close{
        text-align: center;
        font-weight: bold;
    }
    .dropdown-close:hover{
        cursor: pointer;
    }
    .close-cross{
        font-size: 4rem;
        vertical-align: middle;
        margin-right: 8px;
    }

    @media(max-width: 800px){
        .dropdown-wrapper{
            height: calc(100vh - 80px);
            position: fixed;
            top:80px;
            left: 0px;
            width: 100%;
        }
        .dropdown-title:after{
            right: 14px;
        }
    }
</style>
import { querySelectorRequired } from "jslib/utils";
import { doGet, doPost, doXHR, humanDuration, nowMS, sleep } from "./utils";

let startButton: HTMLElement;
let introElement: HTMLElement;
let statusContainerElement: HTMLElement;
let statusElement: HTMLElement;
let resultsContainerElement: HTMLElement;
let resultsTBody: HTMLElement;
let progressElement: HTMLProgressElement;
let scoreGoodElement: HTMLElement;
let scoreBadElement: HTMLElement;


export function onNetworkDiagnosticsPage() {
    introElement = querySelectorRequired("#diagnostics-intro");
    statusContainerElement = querySelectorRequired("#diagnostics-status");
    statusElement = querySelectorRequired("#diagnostics-status label");
    resultsContainerElement = querySelectorRequired("#diagnostics-results");
    resultsTBody = querySelectorRequired("#diagnostics-results tbody");
    progressElement = querySelectorRequired("#diagnostics-progress");
    scoreGoodElement = querySelectorRequired("#diagnostics-score-good");
    scoreBadElement = querySelectorRequired("#diagnostics-score-bad");
    startButton = querySelectorRequired("#diagnostics-start");
    startButton.addEventListener("click", start);
}

function setProgress(pct: number) {
    progressElement.value = pct;
    progressElement.textContent = `${pct}%`;  // IE9
}

function setStatus(status: string) {
    statusElement.innerHTML = `${status}&hellip;`;
}

function setVisibility(element: HTMLElement, isVisible: boolean) {
    element.style.display = (isVisible ? "block" : "none");
}

function addResult(aspect: string, score: string, className: string, notes = "-") {
    const row = document.createElement("tr");
    const aspectCol = document.createElement("td");
    const scoreCol = document.createElement("td");
    const notesCol = document.createElement("td");
    aspectCol.innerHTML = aspect;
    if (className) {
        scoreCol.className = className;
        if (className === "bad") {
            // emphasise the result if it is bad
            notesCol.className = className;
        }
    }
    scoreCol.innerHTML = score;
    notesCol.innerHTML = notes;
    if (aspect === "Connection Latency") {
        notesCol.innerHTML = "<canvas id=\"latency\"></canvas>";
    }
    row.appendChild(aspectCol);
    row.appendChild(scoreCol);
    row.appendChild(notesCol);
    resultsTBody.appendChild(row);
}

function addGoodResult(aspect: string, score: string, notes = "-") {
    addResult(aspect, score, "good", notes);
}

function addBadResult(aspect: string, score: string, notes = "-") {
    addResult(aspect, score, "bad", notes);
}

function addNeutralResult(aspect: string, score: string, notes = "-") {
    addResult(aspect, score, "", notes);
}

async function calcDownloadSpeed() {
    const startMS = performance.now();
    // here append a random number to the URL that the server will ignore, but
    // any unaccounted-for intermediate caching systems will not.
    await doGet(`/static/random10.dat?magic=${Math.round(Math.random() * 10000)}`, 60 * 60 * 1000);
    const durMS = performance.now() - startMS;
    const FILE_SIZE_KB = 10 * 1024;
    return Math.round((FILE_SIZE_KB / durMS) * 1000);
}

function generateRandomBytestring(nBytes: number): string {
    let randomBytes = "";
    for (let i = 0; i < nBytes; i++) {
        // the below ensures a character represented by a single byte under UTF-16 encoding
        const singleByteChar = String.fromCharCode(Math.round(Math.random() * 0x7F));
        randomBytes += singleByteChar;
    }
    return randomBytes;
}

function compareBytestrings(a: string, b: string): boolean {
    return a === b;
}

async function calcUploadSpeed() {
    // NOTE: in a deployed context, vault is served behind nginx via https.
    // in a dev context however, vault is served directly via http.
    const N_UPLOAD_BYTES = 1 * 1024 * 1024;  // 1MB;
    const N_UPLOAD_KB = N_UPLOAD_BYTES / 1024;
    const randomBytes = generateRandomBytestring(N_UPLOAD_BYTES);
    const startMS = performance.now();
    await doPost("/network_diagnostics/test", randomBytes, 60 * 60 * 1000);
    const durMS = performance.now() - startMS;
    return Math.round((N_UPLOAD_KB / durMS) * 1000);
}

async function calcLatency() {
    const latenciesMS: number[] = [];
    const N_LATENCY_SAMPLES = 40;
    const SAMPLE_EVERY_MS = 100;
    for (let i = 0; i < N_LATENCY_SAMPLES; i++) {
        const startMS = nowMS();
        await doXHR("/network_diagnostics/test", "HEAD");
        const durMS = nowMS() - startMS;
        latenciesMS.push(durMS);
        const sleepMS = SAMPLE_EVERY_MS - durMS;
        if (sleepMS > 0) {
            await sleep(SAMPLE_EVERY_MS - durMS);
        }
        const progress = Math.round((i / N_LATENCY_SAMPLES) * 25);
        setProgress(50 + progress);
    }

    const origLatenciesMS = latenciesMS.slice(0);

    // sort latencies
    latenciesMS.sort((a, b) => a - b);

    // work out average latency
    let totalMS = 0;
    for (const latency of latenciesMS) {
        totalMS += latency;
    }
    const avgMS = totalMS / latenciesMS.length;

    // work out lowest latency
    const lowestMS = latenciesMS[0];

    // work out highest latency
    const highestMS = latenciesMS[latenciesMS.length-1];

    // work out 95th percentile
    const index = Math.floor((latenciesMS.length / 100) * 95);
    const percentile = latenciesMS[index];

    return {
        average: Math.round(avgMS),
        dev: Math.round(highestMS - lowestMS),
        max: Math.round(highestMS),
        min: Math.round(lowestMS),
        percentile: Math.round(percentile),
        values: origLatenciesMS,
    };
}

export interface IWSResults {
    browserSupported: boolean;
    canConnect: boolean;
    hasEncounteredDisconnect: boolean;
    isStreamCorrupted: boolean;
}

/**
 * calcWebsocketReliability measures the reliability of a websocket connection
 * to Cydar. It does this by ensuring that:
 * - such a socket can be established
 * - (relatively) large chunks of data can be send and received
 * - no disconnections occur during the process
 */
export function calcWebsocketReliability(
    onProgress = (_progress: number): void => { /* do nothing */ }
): Promise<IWSResults> {
    return new Promise(resolve => {
        const browserSupported = isWebsocketTestPossible();
        let canConnect = false;
        let hasEncounteredDisconnect = false;
        let isStreamCorrupted = false;
        const N_WS_BYTES = 1024 * 1024 * 1; // 1 MB
        const N_WS_ROUNDTRIPS = 10;  // num times to send and recv `N_WS_BYTES`
        const N_WS_IDLE_MS = 20 * 1000;  // 20 seconds
        let lastRandomBytestring = "";
        let nMessagesRecv = 0;
        try {
            // connect to the test websocket endpoint
            const protocol = (window.location.protocol === "https:" ? "wss" : "ws");
            const port = (window.location.port ? ":" + window.location.port : "");
            const endpoint = "/network_diagnostics/ws";
            const connURL = `${protocol}://${window.location.hostname}${port}${endpoint}`;
            const ws = new WebSocket(connURL);
            ws.addEventListener("open", () => {
                lastRandomBytestring = generateRandomBytestring(N_WS_BYTES);
                ws.send(lastRandomBytestring);
            });
            ws.addEventListener("message", event => {
                canConnect = true;
                const msg = event.data as string;
                nMessagesRecv++;
                // compare received message against last sent message
                const isSame = compareBytestrings(msg, lastRandomBytestring);
                if (!isSame) {
                    isStreamCorrupted = true;
                    resolve({ browserSupported, canConnect, hasEncounteredDisconnect, isStreamCorrupted });
                    return;
                }
                if (nMessagesRecv >= N_WS_ROUNDTRIPS) {
                    // now we wait for `N_WS_IDLE_MS` to check that something doesn't disconnect randomly
                    setTimeout(() => {
                        resolve({ browserSupported, canConnect, hasEncounteredDisconnect, isStreamCorrupted });
                    }, N_WS_IDLE_MS);
                    return;
                }
                lastRandomBytestring = generateRandomBytestring(N_WS_BYTES);

                const progress = Math.round((nMessagesRecv / N_WS_ROUNDTRIPS) * 10);
                onProgress(progress);
                ws.send(lastRandomBytestring);
            });
            ws.addEventListener("error", () => {
                hasEncounteredDisconnect = true;
                resolve({ browserSupported, canConnect, hasEncounteredDisconnect, isStreamCorrupted });
            });
            ws.addEventListener("close", () => {
                hasEncounteredDisconnect = true;
                resolve({ browserSupported, canConnect, hasEncounteredDisconnect, isStreamCorrupted });
            });
        } catch (ex) {
            canConnect = false;
            resolve({ browserSupported, canConnect, hasEncounteredDisconnect, isStreamCorrupted });
        }
    });
}

function isWebsocketTestPossible(): boolean {
    return !!(window as any).WebSocket;
}

const NEUTRAL_LATENCY_START = 200;  // somewhat arbitrary; should measure
const BAD_LATENCY_START = 350;


function plotLatency(latencies: {average: number, dev: number, min: number, max: number, values: number[]}) {
    const MAX_LATENCY = Math.max(Math.ceil(latencies.max / 100) * 100, 500);
    const OFFSET_X = 50;
    const OFFSET_Y = 0;
    const plotCanvas: HTMLCanvasElement = querySelectorRequired("#latency");
    plotCanvas.width = plotCanvas.clientWidth;
    plotCanvas.height = plotCanvas.clientHeight;
    const w = plotCanvas.getBoundingClientRect().width;
    const h = plotCanvas.getBoundingClientRect().height;
    const stepX = (w - OFFSET_X) / (latencies.values.length - 1);
    const stepY = (h - OFFSET_Y) / MAX_LATENCY;
    const ctx = plotCanvas.getContext("2d");
    if (!ctx) {
        throw new Error("plotLatency: unable to get 2D canvas context");
    }
    // axis
    ctx.font = "normal 200 12px 'Source Sans Pro'";
    ctx.imageSmoothingEnabled = false;
    ctx.strokeText(`${MAX_LATENCY} ms`, 0.5, 10.5);
    ctx.strokeText("0 ms", 0, h-5);
    // pathing
    ctx.beginPath();
    for (let i = 0; i < latencies.values.length; i++) {
        const x = OFFSET_X + Math.round(stepX * i);
        const y = h - Math.round(stepY * (latencies.values[i])) - OFFSET_Y;
        if (i === 0) {
            ctx.moveTo(x, y);
        } else {
            ctx.lineTo(x, y);
        }
    }
    ctx.stroke();
}

async function start() {
    const NEUTRAL_DL_START = 1024;  // based off filmstrip taking 250ms to load
    const BAD_DL_START = 512;  // based off filmstrip taking 500ms to load

    const NEUTRAL_UL_START = 512;  // based off 300-slice scan taking 5 minutes
    const BAD_UL_START = 256;  // based off 300-slice scan taking 10 minutes

    // tests are in progress
    setVisibility(introElement, false);
    setVisibility(statusContainerElement, true);

    // work out download speed
    setStatus("Establishing download speed");
    const dlSpeedKBps = await calcDownloadSpeed().catch(ex => {
        setTimeout(() => {throw new Error(`PANIC! download test failed: ${ex.status}; check server logs`);}, 0);
    });
    setProgress(25);

    // work out upload speed
    await sleep(500);
    setStatus("Establishing upload speed");
    const ulSpeedKBps = await calcUploadSpeed().catch(ex => {
        setTimeout(() => {throw new Error(`PANIC! upload test failed: ${ex.status}; check server logs`);}, 0);
    });
    setProgress(50);

    // work out latency
    await sleep(500);
    setStatus("Measuring latency");
    const latency = await calcLatency();
    setProgress(75);


    // work out websocket reliability
    await sleep(500);
    setStatus("Checking reliability of sustained connections (do not refresh page)");
    const wsResults = await calcWebsocketReliability(progress => setProgress(75 + progress));
    setProgress(100);
    await sleep(500);

    // save report
    await doPost(window.location.href, {
        download_speed: dlSpeedKBps || 0,
        latencies: latency.values,
        upload_speed: ulSpeedKBps || 0,
        ws_results: {
            browser_supported: wsResults.browserSupported,
            could_connect: wsResults.canConnect,
            encountered_disconnect: wsResults.hasEncounteredDisconnect,
            was_corrupted: wsResults.isStreamCorrupted,
        },
    });
    try {
        // results
        setVisibility(statusContainerElement, false);

        // download
        if (dlSpeedKBps) {
            const opKBytes = 8 * 1024;
            const opSeconds = Math.ceil(opKBytes / dlSpeedKBps);
            const opStr = `A typical map preview would take <span class="slightly-bold">${humanDuration(opSeconds)}</span> to load.`;
            if (dlSpeedKBps <= BAD_DL_START) {
                // slow
                addBadResult("Download Speed", `${dlSpeedKBps} KB/s`, opStr);
            } else if (dlSpeedKBps <= NEUTRAL_DL_START) {
                // neutral -- not known to be bad
                addNeutralResult("Download Speed", `${dlSpeedKBps} KB/s`, opStr);
            } else {
                addGoodResult("Download Speed", `${dlSpeedKBps} KB/s`, opStr);
            }
        } else {
            addBadResult("Download Speed", "N/A", "Download test failed to complete.");
        }

        // upload
        if (ulSpeedKBps) {
            const avgNumPixelsPerSlice = (512 * 512);  // 512 x 512
            const avgSliceBytes = (avgNumPixelsPerSlice * 2);  // U_SHORT
            const avgNumSlices = 300;  // 300
            const scanKBytes = Math.ceil((avgSliceBytes * avgNumSlices) / 1024);
            const sliceSeconds = Math.ceil(scanKBytes / ulSpeedKBps);
            const sliceStr = `A ZIP file containing a 300 slice CT scan will take <span class="slightly-bold">${humanDuration(sliceSeconds)}</span> to upload to Cydar.`;
            if (ulSpeedKBps <= BAD_UL_START) {
                // slow
                addBadResult("Upload Speed", `${ulSpeedKBps} KB/s`, sliceStr);
            } else if (ulSpeedKBps <= NEUTRAL_UL_START) {
                addNeutralResult("Upload Speed", `${ulSpeedKBps} KB/s`, sliceStr);
            } else {
                addGoodResult("Upload Speed", `${ulSpeedKBps} KB/s`, sliceStr);
            }
        } else {
            addBadResult("Upload Speed", "N/A", "Upload test failed to complete.");
        }

        // websocket
        if (wsResults.browserSupported) {
            if (wsResults.canConnect) {  // connection established
                if (!wsResults.hasEncounteredDisconnect) {  // and not disconnected
                    if (!wsResults.isStreamCorrupted) {  // and not corrupted
                        addGoodResult("Map Creator Connection", "-", "A connection to Cydar was able to be sustained.");
                    } else {  // corrupted
                        addBadResult("Map Creator Connection", "-", "Communication failed an integrity check. This may indicate a firewall problem.");
                    }
                } else {  // disconnected
                    addBadResult("Map Creator Connection", "-", "The connection to Cydar was disconnected during tests. This may indicate a firewall problem.");
                }
            } else {
                // could not establish a WS connection -- definitely a problem
                addBadResult("Map Creator Connection", "N/A", "Unable to establish a connection to Cydar. This may indicate a firewall problem.");
            }
        } else {
            // WebSocket is not available
            addBadResult("Map Creator Connection", "N/A", "Websockets are not available on this browser. This does not indicate a firewall problem.");
        }

        if (latency.percentile < NEUTRAL_LATENCY_START) {
            addGoodResult("Connection Latency", `${latency.percentile} ms (&plusmn; ${latency.dev} ms)`);
        } else if (latency.percentile < BAD_LATENCY_START) {
            addNeutralResult("Connection Latency", `${latency.percentile} ms (&plusmn; ${latency.dev} ms)`);
        } else {
            addBadResult("Connection Latency", `${latency.percentile} ms (&plusmn; ${latency.dev} ms)`, "Performance may be degraded creating maps");
        }

        if (
            (Number(dlSpeedKBps) > NEUTRAL_DL_START) &&
            (Number(ulSpeedKBps) > NEUTRAL_UL_START) &&
            (latency.percentile < NEUTRAL_LATENCY_START) &&
            (!wsResults.browserSupported ||
                ((wsResults.canConnect && !wsResults.hasEncounteredDisconnect) &&
                (wsResults.canConnect && !wsResults.isStreamCorrupted)))
        ) {
            setVisibility(scoreGoodElement, true);
        } else {
            setVisibility(scoreBadElement, true);
        }

        setVisibility(resultsContainerElement, true);
        plotLatency(latency);
    } catch (ex) {
        console.log(ex);
        throw ex;
    }
}

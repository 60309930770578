<script lang="ts">
    import DropdownMenu from "./DropdownMenu.svelte";
    import NotificationDropdown from "./NotificationDropdown.svelte";
    import { dropdownShowing } from "./stores";
    import { toggleDropdown } from "./utils";

    export let endpoint = false;
    export let showNotifications = false;
    export let userAuthenticated = false;
    export let userDisplayName: string;
    export let showCompanionAppSignUp = false;
    export let demoMode = false;
</script>

<div id="navbar" class:fixed-dropdown={$dropdownShowing}>
    <div id="logo">
        {#if endpoint}
            <a href="/itg-handover/splash"><img id="header-logo" src='/static/cydar-logo-light.png' alt="Cydar"></a>
        {:else if userAuthenticated}
            <a href="/"><img id="header-logo" src="/static/cydar-logo-light.png" alt="Cydar"></a>
            {#if demoMode}
            <a href="/itg/" class="button button-slim" style="position:relative;top:-25px;margin-left:50px;">Demo maps in surgery</a>
            {/if}
        {:else}
            <img id="header-logo" src="/static/cydar-logo-light.png" alt="Cydar">
        {/if}
    </div>
    
    <div id="navs-container">
        {#if showNotifications}
        <NotificationDropdown/>
        {/if}
        
        {#if userAuthenticated}
        <DropdownMenu userDisplayName={userDisplayName} showCompanionAppSignUp={showCompanionAppSignUp}/>
        {/if}
    </div>
</div>

{#if $dropdownShowing !== null}
    <div on:click={() => toggleDropdown()} class="grey-background"></div>
{/if}


<style>
    #navs-container{
        display: flex;
        flex-direction: row;
        min-width: 0;
    }
    .grey-background {
        position: fixed;
        z-index: 4;
        left: 0;
        top: 80px;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgb(0,0,0);
        background-color: rgba(0,0,0,0.6);
        display: flex;
        z-index: 4;
        align-items: stretch;
    }
    .fixed-dropdown{
        position: fixed;
        z-index: 5;
        width: 100%;
        background-color: #1a0d3f;
    }
</style>
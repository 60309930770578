
declare const NonNegIntType: unique symbol;
export class NonNegativeInteger {

    public static fromString(
        str: string,
        dflt?: NonNegativeInteger
    ): NonNegativeInteger {
        const int = parseInt(str, 10);
        if (isNaN(int)) {
            if (dflt === undefined) {
                throw new Error(`Cannot parse string to integer: ${str}`);
            }
            return dflt;
        }
        return new NonNegativeInteger(int);
    }

    public static create(n: number) {
        return new NonNegativeInteger(n);
    }

    public [NonNegIntType]: void;
    private readonly value: number;
    private constructor(value: number) {
        if (value < 0 || !Number.isInteger(value)) {
            throw new Error(`Not a non-negative integer: ${value}`);
        }
        this.value = value;
    }

    public valueOf() {
        return this.value;
    }

    public toString() {
        return this.value.toString();
    }
}


import { querySelectorAll, querySelectorRequired } from "jslib/utils";
import * as utils from "./utils";

type RetrievalLevel = "STUDY" | "SERIES";

// State transitions for CG queries
export enum QueryStatus {
    NEW = "NEW",
    QUEUED = "QUE",
    RUNNING = "RUN",
    FINISHED = "FIN",
    ERROR = "ERR",
}

type GenericGatewayCallback = (data: IGatewayResponse) => void;

interface IQueryOptions {
    action: any;
    onError: any;
    onErrorResponse: GenericGatewayCallback;
    onFinish: GenericGatewayCallback;
    onNew: GenericGatewayCallback;
    onQueued: GenericGatewayCallback;
    onUpdate: GenericGatewayCallback;
    parameters: object;
    url: string;
    extra_parameters?: IExtraParameters
}

interface IGatewayResponse {
    type: string;
    query_id: number;
    updated: number;
    status: QueryStatus;
    response: any;  // TODO: typed // study[]?
    patient?: number
}

interface IPatients {
    [key: string]: any;
}

interface IExtraParameters {
    patientName?: string;
    patientID?: string;
    patientDOB?: number;
    originPatientID?: string;
    studyDate?: string;
}

// change this constant to adjust minimum instance count requirement
const CYDAR_MIN_INSTANCES = 100;
// these do not need to be modified:
const INTERVAL_POLL_FAST = 250;
const INTERVAL_POLL_SLOW = 1000;
const INTERVAL_POLL_ERROR = 4000;

let studyResultsElement: HTMLElement;
let errorElement: HTMLDivElement;
let errorMessageElement: HTMLParagraphElement;
let errorNameElement: HTMLHeadingElement;
let inputElements: [HTMLInputElement];
let noResultsElement: HTMLDivElement;
let mainLoadingElement: HTMLDivElement;
let messageElement: HTMLElement;
let patientNameInput: HTMLInputElement;
let patientIDInput: HTMLInputElement;
let patientBirthDateInput: HTMLInputElement;

let nhsEnabled: boolean;

let scanLoadingElement: HTMLElement;
let scanModalElement: HTMLElement;
let scanLoadingMessageElement: HTMLElement;

let progressElement: HTMLElement;
let mainLoadingMessageElement: HTMLElement;

/**
 * Handler to be called when entering the "PACS Gateway" page.
 */
export function onPacsPage() {
    if (querySelectorAll("h1.bad").length > 0) {
        return;
    }
    studyResultsElement = querySelectorRequired("#study-results", HTMLDivElement);
    errorElement = querySelectorRequired("#error", HTMLDivElement);
    errorNameElement = querySelectorRequired("#error .error-name", HTMLHeadingElement);
    errorMessageElement = querySelectorRequired("#error .error-message", HTMLParagraphElement);
    inputElements = (querySelectorAll("input") as [HTMLInputElement]);
    noResultsElement = querySelectorRequired("#no_results", HTMLDivElement);
    mainLoadingElement = querySelectorRequired("#main-loading", HTMLDivElement);
    messageElement = querySelectorRequired(".message", HTMLElement);
    patientNameInput = querySelectorRequired("input[name=PatientName]", HTMLInputElement);
    patientIDInput = querySelectorRequired("input[name=PatientID]", HTMLInputElement);
    patientBirthDateInput = querySelectorRequired("input[name=PatientBirthDate]", HTMLInputElement);

    scanLoadingElement = querySelectorRequired("#scan-loading", HTMLElement);
    scanModalElement = querySelectorRequired("#scan-modal", HTMLElement);
    scanLoadingMessageElement = querySelectorRequired("#scan-loading .message", HTMLElement);

    progressElement = querySelectorRequired("#progress", HTMLElement);
    mainLoadingMessageElement = querySelectorRequired("#main-loading .message", HTMLElement);

    nhsEnabled = !!document.querySelector("#nhs_number_th");

    // bind to the "submit" event of <form class="pacsquery"> to perform a PACS Query operation
    querySelectorRequired("form.pacsquery", HTMLFormElement).addEventListener("submit", event => {
        event = event || window.event;
        searchStudies();
        event.preventDefault();
        return false;
    });

    // bind to the "click" event of <button id="back_to_search"> to hide/show certain elements
    querySelectorRequired("#back_to_search", HTMLButtonElement).addEventListener("click", _event => {
        querySelectorRequired("#scan-modal", HTMLDivElement).style.display = "none";
        const elementsToShow = (querySelectorAll("#queryUI, .instructions, .tabs") as [HTMLElement]);
        for (const element of elementsToShow) {
            element.style.display = "block";
        }
    });

    // check if input is prepopulated and seach
    const checkInput = function(inputElement: HTMLInputElement) {
        return inputElement.value ? true : false;
    };

    if ([patientNameInput, patientIDInput, patientBirthDateInput].some(checkInput)) {
        searchStudies();
    }
}

/**
 * Performs a live query of the PACS system (via CG). Returns a promise to the results.
 * @param queryParams - Query parameters to be passed to the Cydar Gateway
 */
export async function queryGateway(queryParams: IQueryOptions) {
    const queryFD = new FormData();
    queryFD.append("action", queryParams.action);
    queryFD.append("parameters", JSON.stringify(queryParams.parameters));
    queryFD.append("patient_info", JSON.stringify(queryParams.extra_parameters));
    const queryXHR = await utils.doPost(queryParams.url, queryFD).catch(ex => {
        queryParams.onError(ex);
        throw ex;
    });
    const queryRes: IGatewayResponse = JSON.parse(queryXHR.responseText);

    let tsLastUpdated: number;
    const queryID = queryRes.query_id;
    if (queryRes.patient && !queryID) {
        window.location.href=`/patient/${queryRes.patient}`;
        return;
    }

    queryParams.onNew(queryRes);
    async function poll() {
        const innerQueryXHR = await utils.doGet(`${queryParams.url}/${queryID}`).catch(ex => {
            // TODO: Check ex contains XHR / `innerQueryXHR` is accessible here.
            if (ex.status !== 403) {
                window.setTimeout(poll, INTERVAL_POLL_ERROR);
            }
            queryParams.onError(ex);
            throw ex;
        });
        const innerQueryRes: IGatewayResponse = JSON.parse(innerQueryXHR.responseText);

        const hasStatusChanged = (innerQueryRes.updated !== tsLastUpdated);
        switch (innerQueryRes.status) {
            case QueryStatus.NEW:
                window.setTimeout(poll, INTERVAL_POLL_FAST);
                break;
            case QueryStatus.QUEUED:
                if (hasStatusChanged) {
                    queryParams.onQueued(innerQueryRes);
                }
                window.setTimeout(poll, INTERVAL_POLL_FAST);
                break;
            case QueryStatus.RUNNING:
                if (hasStatusChanged) {
                    queryParams.onUpdate(innerQueryRes);
                }
                window.setTimeout(poll, INTERVAL_POLL_SLOW);
                break;
            case QueryStatus.FINISHED:
                queryParams.onFinish(innerQueryRes);
                break;
            case QueryStatus.ERROR:
                queryParams.onErrorResponse(innerQueryRes);
                break;
            default:
                break;
        }
        tsLastUpdated = innerQueryRes.updated;
    }
    window.setTimeout(poll, INTERVAL_POLL_FAST);
}

/**
 * Handler for a generic XHR error.
 * @param xhr - underlying XMLHTTPRequest that encountered an error
 */
function onXHRError(_xhr: XMLHttpRequest) {
    errorElement.style.display = "block";
    errorMessageElement.textContent = "The network connection was interrupted; we will keep trying.";
}

/**
 * Handler for a CG / PACS error response, distinct from XHR errors.
 * @param query - the provided query during search operation
 */
function onXHRErrorResponse(query: IGatewayResponse) {
    inputElements.map(inputElement => { inputElement.disabled = false; });
    for (const element of querySelectorAll("#main-loading, .modal, #progress") as [HTMLElement]) {
        element.style.display = "none";
    }
    patientNameInput.focus();
    errorNameElement.textContent = "Sorry, an error has occurred.";
    errorMessageElement.textContent = "Your hospital PACS has reported an error transferring the CT scan data. Cydar Online Support is aware and is working to resolve it...";
    errorElement.style.display = "block";
    console.log(`${query.response.name}:${query.response.message}`);
}


function process_wildcards(input_element: HTMLInputElement) {
    // Remove wildcard typed in by user
    let result = input_element.value.trim().replace(/\*/g, "");

    if (result.length === 0) {
        return result;
    }

    switch (input_element.dataset.wildcardConfig) {
        case "BOTH":
            result = "*" + result + "*";
            break;
        case "SUFFIX":
            result += "*";
            break;
        default:
            // do nothing
    }
    if (input_element.dataset.wildcardSpaceReplace === "true") {
        result = result.replace(/ /g, "*");
    }
    return result;
}


/**
 * Performs a search of the PACS system with parameters being derived from the DOM.
 */
export function searchStudies() {
    queryGateway({
        action: "SEARCH_STUDIES",
        parameters: {
            PatientBirthDate: patientBirthDateInput.value,
            PatientID: process_wildcards(patientIDInput),
            PatientName: process_wildcards(patientNameInput),
        },
        url: document.location.pathname + "/query",
        onNew: (_query: IGatewayResponse) => {  // tslint:disable-line:object-literal-sort-keys
            studyResultsElement.style.display = "none";
            errorElement.style.display = "none";
            inputElements.map(inputElement => { inputElement.disabled = true; });
            noResultsElement.style.display = "none";
            mainLoadingElement.style.display = "block";  // TODO: Verify
            messageElement.textContent = "Connecting to hospital...";
        },
        onQueued: (_query: IGatewayResponse) => {
            mainLoadingElement.style.display = "block";  // TODO: Verify
            messageElement.textContent = "Waiting in queue...";
        },
        onUpdate: (_query: IGatewayResponse) => {
            messageElement.textContent = "Searching hospital archive..";
        },
        onFinish: (query: IGatewayResponse) => {
            mainLoadingElement.style.display = "none";
            inputElements.map(inputElement => { inputElement.disabled = false; });
            patientNameInput.focus();
            if (!query.response.length) {
                // if there were no studies from the gateway
                noResultsElement.style.display = "block";
                return;
            }

            studyResultsElement.style.display = "block";
            errorElement.style.display = "none";

            querySelectorRequired("#study-results .patients tbody", HTMLElement).innerHTML = "";

            const patients: IPatients = {};

            for (const unaggregatedStudy of query.response) {
                const StudyInstanceUID = String(unaggregatedStudy.StudyInstanceUID);
                if (!(StudyInstanceUID in patients)) {
                    patients[StudyInstanceUID] = [unaggregatedStudy];
                }
                else {
                    patients[StudyInstanceUID].push(unaggregatedStudy);
                }
            }

            for (const patient in patients) {
                if (Object.prototype.hasOwnProperty.call(patients, patient)) {
                    const patientRowHTML = `
                        <td class="foreign patient_name">${patients[patient][0].PatientName}</td>
                        <td class="foreign patient_id"><span class="descriptor">Patient ID: </span>${patients[patient][0].PatientID}</td>
                        ${(nhsEnabled ?
                            `<td class="foreign nhs"><span class="descriptor">NHS number: </span>${patients[patient][0].nhs_number || "-"}</td>` :
                            "")}
                        <td class="dob"><span class="descriptor">Date of birth: </span>${utils.humanDA(patients[patient][0].PatientBirthDate)}</td>
                        <td></td>
                    `;
                    const patientRowElement = utils.createTR(patientRowHTML);
                    patientRowElement.className = "patient-row light-blue-background";
                    querySelectorRequired("#study-results .patients tbody", HTMLElement).appendChild(patientRowElement);

                    for (const study of patients[patient]) {
                        // determine whether the current study is suitable according to instance count
                        // 0 == unknown
                        const isStudySuitable = study.instances === 0 || study.instances > CYDAR_MIN_INSTANCES;
                        // create row element to populate with study informaton

                        let rowHTML = `<td colspan="${(nhsEnabled ? 4 : 3)}" class="patient-studies">
                            <div class="study-date"><span class="descriptor">Date: </span>${utils.humanDA(study.StudyDate)},</div>
                            <div class="study-description">${study.StudyDescription},</div>
                            <div class="scans"><span class="descriptor">Scans: </span>${study.scans} scan${study.scans > 1 ? "s": ""},</div>
                            <div class="instances"><span class="descriptor">Images: </span>${study.instances || "Unknown"} image${study.instances > 1 ? "s": ""}</div>
                            </td>`;

                        if (isStudySuitable) {
                            // if suitable, insert action button
                            rowHTML += "<td class=\"status\">";
                            rowHTML += "<span class=\"button button-x-slim\">Import study</span>";

                            if (study.scans > 1 ) {
                                rowHTML += "<a>List scans</a>";
                            }

                            rowHTML += "</td>";
                        } else {
                            rowHTML += " <td class=\"status bad state-UNSU\">Unsuitable</td>";
                        }

                        // create row
                        const rowElement = utils.createTR(rowHTML);
                        rowElement.className = "study-row";

                        if (isStudySuitable) {
                            utils.addClass(rowElement, "selectable");

                            if (study.scans > 1 ) {
                                rowElement.getElementsByTagName("a")[0].addEventListener("click", () => {
                                    listScans(study);
                                });
                            }
                            const patientInfo: IExtraParameters = { patientName: study.PatientName, patientID: study.PatientID, patientDOB: study.PatientBirthDate, originPatientID: patientIDInput.value, studyDate: study.StudyDate };
                            rowElement.getElementsByClassName("button")[0].addEventListener("click", () => {
                                retrieveFromGateway("STUDY", study.StudyInstanceUID, study, patientInfo);
                            });
                        }
                        querySelectorRequired("#study-results .patients tbody", HTMLElement).appendChild(rowElement);
                    }
                    const fakeRow = utils.createTR("");
                    fakeRow.className = "fake-row";
                    querySelectorRequired("#study-results .patients tbody", HTMLElement).appendChild(fakeRow);
                }
            }
        },
        onErrorResponse: onXHRErrorResponse,
        onError: onXHRError,
    });
}

/**
 * "Renders" study metadata to the specified CSS selector.
 * @param selector - CSS selector string to "render" to
 * @param study - study result data (with PHI)
 */
function renderMetadata(selector: string, study: any) {  // TODO: type study
        const targetElement = querySelectorRequired(selector, HTMLElement);
        targetElement.innerHTML = `
            <div class="kv">
                <div class="key">Patient Name</div>
                <div class="selectable value">${study.PatientName}</div>
            </div>
            <div class="kv">
                <div class="key">Date of Birth</div>
                <div class="selectable value">${utils.humanDA(study.PatientBirthDate)}</div>
            </div>
            <div class="kv">
                <div class="key">Patient ID</div>
                <div class="selectable value">${study.PatientID}</div>
            </div>
            ${study.nhs_number ?
                `<div class="kv"><div class="key">NHS Number</div><div class="selectable value">${study.nhs_number}</div></div>` :
                ""}
            <div class="kv">
                <div class="key">CT Study Date</div>
                <div class="selectable value">${utils.humanDA(study.StudyDate)}</div>
            </div>
            <div class="kv">
                <div class="key">Study Description</div>
                <div class="selectable value">${study.StudyDescription}</div>
            </div>`;
}

/**
 * Lists all scans to DOM (after having retrieved using `queryGateway`) for the specified "study" instance.
 * @param study - a unique study locatable in the remote PACS system
 */
function listScans(study: any) {
        (querySelectorAll("#queryUI, .instructions, .tabs, #scan-data") as [HTMLElement]).map(e => { e.style.display = "none"; });
        inputElements.map(inputElement => { inputElement.disabled = true; });
        scanLoadingElement.style.display = "block";
        scanModalElement.style.display = "block";

        scanModalElement.scrollIntoView();

        renderMetadata("#scan-modal .metadata", study);
        queryGateway({
            action: "LIST_SCANS",
            parameters: {
                StudyInstanceUID: study.StudyInstanceUID,
            },
            url: document.location.pathname + "/query",
            onNew: (_query: IGatewayResponse) => {  // tslint:disable-line:object-literal-sort-keys
                scanLoadingMessageElement.textContent = "Connecting to hospital...";
            },
            onQueued: (_query: IGatewayResponse) => {
                scanLoadingMessageElement.textContent = "Waiting in queue...";
            },
            onUpdate: (_query: IGatewayResponse) => {
                scanLoadingMessageElement.textContent = "Listing scans in study...";
            },
            onFinish: (query: IGatewayResponse) => {
                const patientInfo: IExtraParameters = { patientName: study.PatientName, patientID: study.PatientID, patientDOB: study.PatientBirthDate, originPatientID: patientIDInput.value, studyDate: study.StudyDate };
                mainLoadingElement.style.display = "none";
                inputElements.map(inputElement => { inputElement.disabled = false; });
                scanLoadingElement.style.display = "none";

                if (!query.response.length) {
                    querySelectorRequired("#no_scan_results", HTMLDivElement).style.display = "block";
                    return;
                }

                querySelectorRequired("#scan-data", HTMLElement).style.display = "block";


                querySelectorRequired("#scan-data tbody").innerHTML = "";  // .empty()  // TODO: Is this required?
                let lastScan: any;
                for (const scan of query.response) {
                    // a scan is deemed psuedo-suitable if both:
                    //   - it either has no `instances` count, or the `instances` count of its container study exceeds 100
                    //   - its `modality` field equals "CT"
                    const hasSufficientInstances = scan.instances === 0 || scan.instances > CYDAR_MIN_INSTANCES;
                    const hasCTModality = scan.Modality.indexOf("CT") > -1;
                    const isScanSuitable = (hasSufficientInstances && hasCTModality);
                    let rowHTML = `<td class="series"><span class="descriptor">Series: </span>${scan.SeriesNumber}</td>
                        <td class="foreign desc">${scan.SeriesDescription}</td>
                        <td class="foreign acqdate"><span class="descriptor">Date: </span>${utils.humanDA(scan.AcquisitionDate)}</td>
                        <td class="${hasSufficientInstances ? "" : "bad "}images"><span class="descriptor">Images: </span>${scan.instances || "Unknown"}</td>
                        <td class="${hasCTModality ? "" : "bad "}modality"><span class="descriptor">Modality: </span>${scan.Modality}</td>
                    `;

                    if (isScanSuitable) {
                        rowHTML += "<td class=\"status\"><span class=\"button button-slim button-full-width\">Import scan</span></td>";
                    } else {
                        rowHTML += "<td class=\"status\"><span class=\"button button-slim button-full-width disabled\">Unsuitable</span></td>";
                    }

                    // create row
                    const rowElement = utils.createTR(rowHTML);
                    rowElement.classList.add("study");

                    if (isScanSuitable) {
                        (function(innerScan) {  // scope
                            rowElement.addEventListener("click", () => {
                                retrieveFromGateway("SERIES", innerScan.SeriesInstanceUID, study, patientInfo);
                            });
                        })(scan);

                        rowElement.className += " selectable";
                    } else {

                        rowElement.className += " status row-disabled bad state-UNSU";
                    }

                    querySelectorRequired("#scan-data tbody").appendChild(rowElement);
                    lastScan = scan;
                }

                // all are same therefore last will do
                querySelectorRequired("#transfer_all", HTMLElement).addEventListener("click", () => {
                    retrieveFromGateway("STUDY", lastScan.StudyInstanceUID, study, patientInfo);
                });
            },
            onErrorResponse: onXHRErrorResponse,
            onError: onXHRError,
        });
}

/**
 * Retrieves a study/scan from the remote PACS system via CG. Updates DOM.
 * @param level Q+R Retrieval level (STUDY || SERIES)
 * @param uid - [Study|Series]InstanceUID, as per DICOM specs.
 * @param study - study metadata string (optional)
 */
export function retrieveFromGateway(level: RetrievalLevel, uid: string, study: string, patientIdentifiers: IExtraParameters) {

    // initialise dom
    (querySelectorAll("#queryUI, .instructions, .tabs") as [HTMLElement]).map(e => { e.style.display = "none"; });
    mainLoadingElement.style.display = "none";
    scanModalElement.style.display = "none";
    progressElement.style.display = "flex";
    mainLoadingMessageElement.textContent = "";
    let queryAction: string;
    let queryParameters: object;

    switch (level) {
        case "STUDY":
            queryAction = "RETRIEVE_STUDY";
            queryParameters = {
                StudyInstanceUID: uid,
            };
            break;
        case "SERIES":
            queryAction = "RETRIEVE_SCAN";
            queryParameters = {
                SeriesInstanceUID: uid,
            };
            break;
        default:
            throw new Error(`unrecognised retrieval level: ${level}`);
    }

    window.scrollTo(0,0);

    queryGateway({
        action: queryAction,
        parameters: queryParameters,
        extra_parameters: patientIdentifiers,
        url: document.location.pathname + "/query",
        onNew: (_query: IGatewayResponse) => { /* do nothing */ },
        onQueued: (_query: IGatewayResponse) => { /* do nothing */ },
        onUpdate: (query: IGatewayResponse) => {
            window.location.assign(`/patient/${query.patient}`);
        },
        onFinish: () => null,
        onErrorResponse: onXHRErrorResponse,
        onError: onXHRError,
    });
}

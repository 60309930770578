<script lang="ts">
    import GenericDropdownMenu from "./GenericDropdownMenu.svelte";
    import NavbarItem from "./NavbarItem.svelte";
    import NotificationCounter from "./NotificationCounter.svelte";
    import { dropdownShowing } from "./stores";
    import { toggleDropdown } from "./utils";
    
    export let userDisplayName: string;
    export let showCompanionAppSignUp = false;
    const thisDropdown = "menu";
</script>

<NavbarItem>
    <div id="dropdown" on:click={() => toggleDropdown(thisDropdown)}>
        <span class="user-details">{userDisplayName}</span>
        <i class="fa {$dropdownShowing === 'menu'?'fa-chevron-up':'fa-chevron-down'}">&nbsp;</i>
    </div>
        
    {#if $dropdownShowing === thisDropdown}
    <GenericDropdownMenu title="Menu" onClose={() => toggleDropdown()}>
        <a class="dropdown-item" href="/notifications"><i class="dropdown-icon fa fa-bell"></i>
            <NotificationCounter/>
        </a>
        <a class="dropdown-item" href="/feedback"><i class="dropdown-icon fa fa-question-circle"></i>Need Help?</a>
        {#if showCompanionAppSignUp}
        <a class="dropdown-item" href="/companion/setup"><i class="dropdown-icon fa fa-mobile"></i>Setup Companion App</a>
        {/if}
        <a class="dropdown-item" href="/accounts/user"><i class="dropdown-icon fa fa-user"></i>Account</a>
        <a class="dropdown-item" href="/accounts/logout/"><i class="dropdown-icon fa fa-sign-out"></i>Log Out</a>
    </GenericDropdownMenu>
    {/if}
</NavbarItem>

<style>

#dropdown .user-details{
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
}

.dropdown-item{
    display: flex;
    align-items: center;
    padding: 16px;
    font-weight: bold;
}

#dropdown{
    display: flex;
    height: 54px;
    align-items: center;
    margin-top: 16px;
    margin-right: 16px;
    min-width: 0;
}
#dropdown:hover{
    cursor: pointer;
}

#dropdown span{
    margin-right: 8px;
}

.dropdown-icon{
    margin-right: 8px;
    font-size: 3rem;
    width: 40px;
}
</style>
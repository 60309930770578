<script lang="ts">
    export let mobileHide = false;
</script>

<div class:mobileHide class="dropdown-item">
    <slot/>
</div>

<style>
.dropdown-item{
    height: 80px;
    position: relative;
    margin-right: 16px;
    min-width: 0;
}
@media (max-width:800px){
    .mobileHide{
        display: none;
    }
    .dropdown-item{
        margin-right: 0px;
    }
}
</style>
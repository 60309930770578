<script lang="ts">
    import { unreadNotificationStore, readNotificationStore } from "./stores";

    function goToURL(url: string) {
        window.location.href = url;
    }
</script>

<div class="unread">
    <h2>New messages</h2>
    {#each $unreadNotificationStore as notification}
        <div class="notification" on:click={() => goToURL(notification.url)}>
            <div class="notification-icon">
            {#if notification.icon}
            <img src="/static/scanmanager/patient_flow/{notification.icon}.png" alt="{notification.icon}">
            {:else}
            <div class="circle"></div>
            {/if}
            </div>
            <div class="notification-body">
                <h3>{notification.patient}</h3>
                <p>{notification.subject}</p>
                <div class="notification-footer">{notification.created} ago</div>
            </div>
        </div>
    {:else}
        <p>No new messages</p>
    {/each}
</div>

<div class="read">
    <h2>Read messages</h2>
    {#each $readNotificationStore as notification}
        <div class="notification" on:click={() => goToURL(notification.url)}>
            <div class="notification-icon">
            {#if notification.icon}
            <img src="/static/scanmanager/patient_flow/{notification.icon}.png" alt="{notification.icon}">
            {:else}
            <div class="circle"></div>
            {/if}
            </div>
            <div class="notification-body">
                <h3>{notification.patient}</h3>
                <p>{notification.subject}</p>
                <div class="notification-footer">{notification.created} ago</div>
            </div>
        </div>
    {/each}
</div>

<style>
    .notification{
        display: flex;
        border: 1px solid rgb(222, 220, 220);
        padding: 16px;
        align-items: center;
        margin-bottom: 8px;
    }
    .unread .notification{
        border-left: 8px solid rgb(222, 220, 220);
    }
    .read h2{
        margin-top: 24px;
    }
    .notification:hover{
        background-color: rgb(249, 247, 253);
        cursor: pointer;
    }
    .notification-icon{
        margin-right: 32px;
    }
    .notification-body h3{
        font-size: 2rem;
        margin-bottom: 8px;
        word-break: break-word;
    }
    .notification-body p{
        margin-bottom: 8px;
    }
    .circle{
        border-radius: 50%;
        width: 80px;
        height: 80px;
        background-color: #3f228a;
    }
</style>
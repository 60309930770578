/**
 * Library to allow control of an arbitrary HTML interface using a d-pad remote
 */

export type Direction = "LEFT"|"RIGHT"|"UP"|"DOWN";

export const UNTARGETABLE = -1;

// each direction has a 50 degree FOV, plus a low weighted 180 degree FOV.
// There is also a high weighting for parallel movements.
//
// an order of magnitude, to avoid confusing movements when moving down the
// aorta. Peripheral points in the aorta are approximately 6x the distance away
// from the nearest UI element. A factor of 10 has a lot of safety margin.
export const PERIPHERAL_FACTOR = 10;

// less than sqrt(2) to allow arrow shoot-through without inducing unexpected
// movements on the aorta (jumping several points down if one happens to be
// parallel)
export const ON_AXIS_FACTOR = 0.3;

export function calcAngle(dx: number, dy: number): number {
    let angle = Math.atan(dx/dy) * 180/Math.PI;

    // angle relative to 12:00
    if (dy >= 0 && dx >= 0) {
        angle = 180 - angle;
    } else if (dx < 0 && dy >= 0) {
        angle = 180 - angle;
    } else if (dx > 0 && dy < 0) {
        angle = -angle;
    } else {
        angle = 360-angle;
    }

    return angle;
}

// calculates the distance to the next element from the axis of movement
export function distanceInDirection(direction: Direction, start: number[], given: number[]): number {
    const dx = given[0] - start[0];
    const dy = given[1] - start[1];
    const r = Math.sqrt(dy**2 + dx**2);
    const angle = calcAngle(dx, dy);

    // most sensitivity directly parallel, followed by quadrant + margin,
    // followed by half circle.
    switch (direction) {
        case "LEFT":
            if (angle > 220 && angle < 320) {
                return Math.abs(dy) < 1 ? r*ON_AXIS_FACTOR : r;
            } else if (angle > 180 && angle < 360) {
                return r*PERIPHERAL_FACTOR;
            } else {
                return UNTARGETABLE;
            }
        case "RIGHT":
            if (angle > 40 && angle < 140) {
                return Math.abs(dy) < 1 ? r*ON_AXIS_FACTOR : r;
            } else if (angle > 0 && angle < 180) {
                return r*PERIPHERAL_FACTOR;
            } else {
                return UNTARGETABLE;
            }
        case "UP":
            if (angle > 310 || angle < 50) {
                return Math.abs(dx) < 1 ? r*ON_AXIS_FACTOR : r;
            } else if (angle > 270 || angle < 90) {
                return r*PERIPHERAL_FACTOR;
            } else {
                return UNTARGETABLE;
            }
        case "DOWN":
            if (angle > 130 && angle < 240 ) {
                return Math.abs(dx) < 1 ? r*ON_AXIS_FACTOR : r;
            } else if (angle > 90 && angle < 270) {
                return r*PERIPHERAL_FACTOR;
            } else {
                return UNTARGETABLE;
            }
        default:
            return UNTARGETABLE;
    }
}


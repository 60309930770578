import type { dropdownOptions } from "./interfaces";
import { dropdownShowing } from "./stores";
import { get } from "svelte/store";
import * as  utils from "../utils";
import { notificationStore } from "./stores";

export function toggleDropdown(dropdown: dropdownOptions = null): void {
    if (get(dropdownShowing) === dropdown) {
        dropdownShowing.set(null);
    }
    else {
        dropdownShowing.set(dropdown);
    }
}

export async function markAsRead(notification_id: number, errorCallback?: () => any) {
    try {
        notificationStore.update((notifications) => {
            const toUpdate = notifications.find((notification) => notification.id === notification_id);
            if (toUpdate) {
                toUpdate.read = true;
            }
            return notifications;
        });
        const data = new FormData();
        data.append("id", String(notification_id));
        await utils.doPost("/notifications", data);
    }
    catch {
        if (errorCallback) {
            errorCallback();
        }
    }
}
